.app-title {
  background-color: #232f3e;
  margin-top: 0px;
  padding: 30px;
  color: #ffffff;
  background-position: 0;
  background-size: cover;
  background-image: url("//d1.awsstatic.com/management-console/ImageHeader_Sign-out-Console_Hero2_Blue.2ea44d1ab1a2270a80c1f6dfc2b41f6b5d69b7b9.jpg");
}

.app-title-main-text {
  font-size: 4.4rem;
  font-size: 40px;
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 5px;
}

.app-intro-area {
  padding: 40px;
}

.app-intro-area-text {
  font-size: 1.8rem;
  line-height: 1.2;
  font-weight: 200;
}

.app-loading-div {
  margin: auto;
  width: 50%;
  text-align: center;
  padding: 50px;
}

@keyframes outer {
  50% {
    transform: translateY(0);
  }
  25%,
  75% {
    transform: translateY(4px);
  }
}
@keyframes body {
  50% {
    transform: rotate(16deg);
  }
  25%,
  75% {
    transform: rotate(24deg);
  }
}
@keyframes arm-leg {
  50% {
    transform: rotate(var(--r-to));
  }
}
@keyframes arm-b {
  30%,
  70% {
    transform: rotate(var(--r-to));
  }
}
html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}
* {
  box-sizing: inherit;
}
*:before,
*:after {
  box-sizing: inherit;
}
