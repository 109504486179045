.rvr-combine-key-container {
  padding-left: 20px;
  padding-right: 20px;
}

.rvr-confirmed-combine-container {
  display: flex;
  flex-wrap: wrap;
}

.rvr-confim-combine-btn {
  margin-top: 20px;
}

.rvr-combine-key-label {
  font-size: 1.7rem;
  margin-bottom: 13px;
}

.rvr-combine-inner-key-label {
  font-size: 1.4rem;
  font-weight: 600;
}

.each-rvr-combine {
  padding: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  word-wrap: normal;
  border: 1px solid #02a1c9 !important;
  background-color: #f1faff;
}

.each-rvr-combine-remove-btn {
  float: right;
}
