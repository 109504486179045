.dsn-to-lab-element {
  min-width: 130px;
  max-width: 230px;
  margin-right: 10px;
  padding: 10px 0;
}

.dsn-to-lab {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}

.dsn-to-lab-add-btn {
  padding-left: 10px;
  padding-top: 40px;
}

.dsn-token-label {
  font-size: 1.7rem;
  max-width: 330px;
  padding-top: 15px;
  margin-right: 20px;
}

.dsn-token-group {
  display: flex;
  flex-wrap: wrap;
}
