.each-combine {
  padding: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  word-wrap: normal;
  border: 1px solid #02a1c9 !important;
  background-color: #f1faff;
  max-width: 200px;
}

.each-combine-remove-btn {
  float: right;
  margin-bottom: 10px;
}

.confim-combine-btn {
  margin-top: 20px;
}

.confim-keys-session {
  margin-top: 20px;
}

.combine-inner-key-label {
  font-size: 1.4rem;
  font-weight: 600;
}