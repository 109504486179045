.test-plan-name {
  font-size: 1.7rem;
  margin-bottom: 5px;
}

.test-type-lvl-label {
  font-weight: 500;
  /* font-style: italic; */
}

.test-plan-group {
  margin-bottom: 13px;
}

.test-plan-detail {
  padding-left: 10px;
  margin-bottom: 13px;
}

.test-plan-field-element {
  min-width: 130px;
  max-width: 230px;
  margin-right: 10px;
  padding: 10px 0;
}

.test-type-group {
  margin-right: 10px;
}

.test-type-lvl-element {
  display: flex;
  flex-wrap: wrap;
  padding-left: 20px;
}
.test-plan-field-select {
  min-width: 130px;
  max-width: 230px;
}
