.request-form-submit-btn {
  float: right;
  margin-right: 20px;
  position: relative;
  bottom: 5px;
}

.form-footer {
  height: 40px;
}

.dropdown-element {
  min-width: 130px;
  max-width: 230px;
  margin-right: 10px;

  padding: 10px 0;
}

.form-input-element {
  padding: 5px;
}

.form-device-name-dropdown {
  max-width: 700px;
  margin: 0;
}

.form-device-name-dsn {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 0 !important;
}

.dropdown-list {
  display: flex;
  flex-wrap: wrap;
}

.form-device-label-text {
  padding-top: 5px;
}

.form-device-id-dropdown {
  padding-bottom: 0 !important;
}
