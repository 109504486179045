.compliance-valid-combine-info {
  font-weight: 600;
}

.compliance-valid-plan-text {
  font-weight: 550;
  color: var(--test-status-passed);
}

.compliance-invalid-plan-text {
  font-weight: 550;
  color: var(--test-status-failed);
}

.compliance-valid-combine {
  display: flex;
}

.compliance-validPlan {
  width: 48%;
  padding: 7px;
}

.compliance-validPlan-each {
  display: flex;
  flex-wrap: wrap;
}

.compliace-valid-each {
  margin: 5px;
  margin-bottom: 5px;
  border: 1px solid #00a1c9;
  background-color: #f1fafe;
  padding: 5px;
}

.icon-btn {
  background-color: transparent;
  border: none;
  display: inline-block;
  cursor: pointer;
  -webkit-appearance: button;
  outline: none !important;
  color: "blue";
}

.icon-btn:hover {
  animation: rotate-keyframes 0.5s;
  color: #205081;
  background-color: transparent;
  /* transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg); */
}

.icon-btn:active {
  transform: translateY(1px);
}
